import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { queryManager } from 'ember-apollo-client';
import { task, dropTask } from 'ember-concurrency';
import anyEnabledTerminalQuery from 'my-phorest/gql/queries/any-enabled-terminal.graphql';
import currentTillBalanceQuery from 'my-phorest/gql/queries/current-till-balance.graphql';
import { sumMoneyCollection, addMoney } from 'my-phorest/utils/currency';

export default class TillBalanceSlideOverService extends Service {
  @queryManager apollo;

  @service session;
  @service terminalLoader;

  @tracked isOpen = false;
  @tracked balances;
  @tracked integratedPayments;

  open() {
    this.isOpen = true;

    this.loadBalancesTask.perform();
  }

  close() {
    this.isOpen = false;
  }

  @dropTask
  *loadBalancesTask() {
    let tillBalanceResponse =
      yield this.loadPaymentMethodBalancesTask.perform();

    this.balances = tillBalanceResponse.balances;
    this.integratedPayments = tillBalanceResponse.integratedPaymentTotals;
  }

  get totalAmount() {
    let balancesTotal = sumMoneyCollection(this.balances, 'expectedAmount');
    let integratedPaymentTotal = sumMoneyCollection(
      this.integratedPayments,
      'amount'
    );

    return addMoney(balancesTotal, integratedPaymentTotal);
  }

  @task
  *currentTerminalTask() {
    if (this.session.terminalId) {
      const terminal = yield this.terminalLoader.getTerminal.perform(
        this.session.terminalId
      );
      if (terminal.tillId) {
        return terminal;
      }
    }

    const anyEnabledTerminalResponse = yield this.apollo.query({
      query: anyEnabledTerminalQuery,
      variables: {
        branchId: this.session.branchId,
      },
    });
    return anyEnabledTerminalResponse.anyEnabledTerminal;
  }

  @task
  *loadPaymentMethodBalancesTask() {
    const terminal = yield this.currentTerminalTask.perform();
    const tillId = terminal.tillId;
    const currentTillBalanceResponse = yield this.apollo.query({
      query: currentTillBalanceQuery,
      variables: {
        tillId,
      },
      fetchPolicy: 'network-only',
    });

    return currentTillBalanceResponse.currentTillBalance;
  }
}
