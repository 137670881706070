import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ClientTravelCardPrinterService extends Service {
  @service session;
  @service electronApp;
  @service router;
  @service('browser/window') window;

  @tracked isPrinting = false;
  @tracked isBrowserPrintOpen = false;
  @tracked appointmentId;

  @action
  print(appointmentId) {
    this.isPrinting = true;
    this.appointmentId = appointmentId;

    if (this.electronApp.isRunningInElectron) {
      const path = this.router.urlFor(
        'accounts.account.print.appointment-client-travel-card',
        this.session.currentAccountId,
        appointmentId
      );
      const { protocol, host } = this.window.location;
      const printURL = new URL(`${protocol}//${host}${path}`);
      return this.electronApp.printReceipt(
        printURL.toString(),
        {
          successCb: () => {
            this.isPrinting = false;
          },
        },
        {
          waitForDataLoadedAttr: true,
        }
      );
    } else {
      this.isBrowserPrintOpen = true;
    }
  }

  @action
  onDone() {
    this.isPrinting = false;
    this.isBrowserPrintOpen = false;
  }
}
