import Controller from '@ember/controller';
import { service } from '@ember/service';

export default class IntegrationsController extends Controller {
  @service router;
  @service('browser/session-storage') sessionStorage;
  @service('browser/window') window;

  url;

  queryParams = ['key', 'code', 'state'];

  performRedirect = () => {
    let hash = `?disableClockIn=true#${this.key}`;
    let path = `/auto-login/v2`;

    const { protocol, host } = this.window.location;
    const url = new URL(`${protocol}//${host}${path}${hash}`).toString();
    this.sessionStorage.setItem('code', this.code);
    this.sessionStorage.setItem('state', this.state);
    this.window.location.replace(url);
  };
}
