import Service from '@ember/service';
import { service } from '@ember/service';

export default class EmbeddedRedirectionsService extends Service {
  @service session;

  get businessId() {
    return this.session.business.id;
  }

  get branchId() {
    return this.session.branch.id;
  }

  get businessPath() {
    return `businesses/${this.businessId}`;
  }

  get branchPath() {
    return `${this.businessPath}/branches/${this.branchId}`;
  }

  get clientCategoriesPath() {
    return `${this.branchPath}/manager/client-categories`;
  }

  get clientSourcesPath() {
    return `${this.branchPath}/manager/sources`;
  }

  get integrationsPath() {
    return `${this.branchPath}/manager/integrations`;
  }

  get chainStaffPath() {
    return `${this.branchPath}/manager/chain-library/staff`;
  }

  get staffListPath() {
    return `${this.branchPath}/manager/staff/list`;
  }

  get staffLibraryPath() {
    return `${this.branchPath}/manager/chain-library/staff/all`;
  }

  get staffRosterPath() {
    return `${this.branchPath}/manager/roster`;
  }

  get staffCategoriesPath() {
    return `${this.branchPath}/manager/staff-categories`;
  }

  get staffAccessPath() {
    return `${this.branchPath}/manager/access/access-levels`;
  }

  get phorestPayPath() {
    return `${this.branchPath}/manager/phorest-pay/payouts`;
  }

  get serviceListPath() {
    return `${this.branchPath}/manager/branch-services`;
  }

  get chainServiceListPath() {
    return `${this.branchPath}/manager/business-services`;
  }

  get serviceCategoriesPath() {
    return `${this.branchPath}/manager/service-categories`;
  }

  get onlineStorePath() {
    return `${this.branchPath}/manager/online-store`;
  }

  get payrollReportsPath() {
    return `${this.branchPath}/manager/payroll-reports`;
  }

  get businessPayrollReportsPath() {
    return `${this.branchPath}/manager/payroll-reports/business`;
  }

  get productCategoriesPath() {
    return `${this.branchPath}/manager/product-categories`;
  }

  get reportsPath() {
    return `${this.branchPath}/manager/reports/insights/health_check`;
  }

  get branchSettingsPath() {
    return `${this.branchPath}/settings/general/contact-info`;
  }

  get branchOpeningHoursSettingsPath() {
    return `${this.branchPath}/settings/general/opening-hours`;
  }

  get consultationFormsPath() {
    return `${this.branchPath}/manager/consultations`;
  }

  get notificationsPath() {
    return `${this.branchPath}/manager/notifications/overview`;
  }

  get managerPath() {
    return `${this.branchPath}/manager`;
  }

  get reviewsPath() {
    return `${this.branchPath}/marketing/reviews/overview`;
  }

  get campaignsPath() {
    return `${this.branchPath}/marketing/campaigns`;
  }

  get addMachinePath() {
    return `${this.branchPath}/settings/general/facilities/machines/new`;
  }

  get addRoomPath() {
    return `${this.branchPath}/settings/general/facilities/rooms/new`;
  }

  get marketingPath() {
    return `${this.branchPath}/marketing`;
  }

  get consultationsVisitsPath() {
    return `${this.branchPath}/manager/consultations/visits`;
  }

  get consultationsFormTemplatesPath() {
    return `${this.branchPath}/manager/forms`;
  }

  get consultationsTreatmentPlanTemplatesPath() {
    return `${this.branchPath}/manager/consultations/treatment-plan-templates`;
  }

  get consultationsSettingsPath() {
    return `${this.branchPath}/manager/consultations/settings`;
  }

  get consultationsAutoSendFormsPath() {
    return `${this.branchPath}/marketing/reminders/consultation-forms/email`;
  }

  get newSmsCampaignPath() {
    return `${this.branchPath}/marketing/campaigns/sms`;
  }

  get newEmailCampaignPath() {
    return `${this.branchPath}/marketing/campaigns/email`;
  }

  trimBusinessAndBranchPart(path) {
    let arr = path.split('/');
    let businessIx = arr.findIndex((i) => i === 'businesses');
    if (businessIx > -1) {
      arr.splice(businessIx, 2);
    }
    let branchIx = arr.findIndex((i) => i === 'branches');
    if (branchIx > -1) {
      arr.splice(branchIx, 2);
    }
    return arr.join('/');
  }
}
