import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { registerDestructor } from '@ember/destroyable';

export default class VoucherSelectorService extends Service {
  @service router;
  @tracked previousUrl = null;
  @tracked selectedVoucher = null;

  startSelectingVoucher() {
    const { currentURL } = this.router;
    this.previousUrl = currentURL;

    this.router.transitionTo('accounts.account.vouchers.client-vouchers', {
      queryParams: { selectingVoucher: true },
    });
  }

  @action
  selectVoucher(voucher) {
    if (this.previousUrl) {
      this.selectedVoucher = voucher;
      this.router.transitionTo(this.previousUrl);
    } else {
      this.discardSelectedVoucher();
      this.router.transitionTo('/');
    }
  }

  @action
  cancelSelectingVoucher() {
    this.router.transitionTo(this.previousUrl);
    this.discardSelectedVoucher();
  }

  executeIfHasVoucher(callback, component) {
    this.previousUrl = null;
    if (this.selectedVoucher) {
      callback(this.selectedVoucher);
    }

    registerDestructor(component, this.discardSelectedVoucher);
  }

  @action
  discardSelectedVoucher() {
    this.selectedVoucher = null;
  }
}
