import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { today } from 'my-phorest/utils/local-date-helpers';
import { task, waitForProperty } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import clientQuery from 'my-phorest/components/slide-over/appointment/-client-loader/client.graphql';
import UpdateBasketLoyaltyCardPresented from 'my-phorest/gql/mutations/update-basket-loyalty-card-presented.graphql';

export const LOCAL_STORAGE_KEY = 'TREAT-CARDS-PRESENTED';

export default class ScanTreatCardModalService extends Service {
  @service session;
  @service('browser/local-storage') localStorage;

  @queryManager apollo;

  @tracked isOpen = false;

  @action
  close() {
    this.isOpen = false;
  }

  @action
  open() {
    this.isOpen = true;
  }

  @task
  *askAndWaitForModalActionTask() {
    this.open();
    yield waitForProperty(this, 'isOpen', false);
  }

  @task
  *maybeAskForNumberTask(clientId) {
    let shouldOpen = yield this.shouldOpenModalTask.perform(clientId);
    if (shouldOpen) {
      yield this.askAndWaitForModalActionTask.perform();
    }
  }

  @task
  *shouldOpenModalTask(clientId) {
    if (this.session.branch.isPresentingTreatcardNeeded) {
      let hasSerial = yield this.hasSerialClient.perform(clientId);
      if (hasSerial && !this.wasCardPresentedToday(clientId)) {
        return true;
      }
    }
    return false;
  }

  @task
  *updateBasketWhenTreatCardPresented(basket) {
    if (this.wasCardPresentedToday(basket.client.id)) {
      return yield this.apollo.mutate(
        {
          mutation: UpdateBasketLoyaltyCardPresented,
          variables: { basketId: basket.id },
        },
        'updateBasketLoyaltyCardPresented'
      );
    }
  }

  wasCardPresentedToday(clientId) {
    let map = this.#getPresentedTreatCards();
    if (map) {
      return map.date === today() && map.clientIds.includes(clientId);
    }
    return false;
  }

  addClientIdToPresentedCards(clientId) {
    let list = this.#getPresentedTreatCards();
    let clientIds = new Set(list.clientIds);
    clientIds.add(clientId);
    list.clientIds = [...clientIds];
    this.#saveList(list);

    return this.#getPresentedTreatCards();
  }

  get key() {
    return `${this.session.branchId}:${LOCAL_STORAGE_KEY}`;
  }

  @task
  *fetchClientTask(clientId) {
    return yield this.apollo.query(
      {
        query: clientQuery,
        variables: {
          id: clientId,
        },
      },
      'client'
    );
  }

  get client() {
    return this.fetchClientTask.lastSuccessful?.value;
  }

  @task
  *hasSerialClient(clientId) {
    let client = yield this.fetchClientTask.perform(clientId);
    return !!client.treatCard?.serial;
  }

  #getPresentedTreatCards() {
    let list = this.#readList();
    if (list) {
      list = JSON.parse(list);
      if (list.date === today()) {
        return list;
      } else {
        this.#createEmptyTodayList();
      }
    } else {
      this.#createEmptyTodayList();
    }
    return this.#getPresentedTreatCards();
  }

  #saveList(list) {
    return this.localStorage.setItem(this.key, JSON.stringify(list));
  }

  #readList() {
    return this.localStorage.getItem(this.key);
  }

  #createEmptyTodayList() {
    let list = {
      date: today(),
      clientIds: [],
    };
    this.#saveList(list);
  }
}
