import Service, { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { queryManager } from 'ember-apollo-client';
import LongLivedBasket from 'my-phorest/gql/mutations/long-lived-basket.graphql';
import { today } from 'my-phorest/utils/local-date-helpers';
import AppointmentIdsByDate from 'my-phorest/gql/queries/appointment-ids-by-date.graphql';
import { fetchAllPaginatedResults } from 'my-phorest/utils/graphql';

export default class ReuseBasketService extends Service {
  @queryManager apollo;
  @service paymentSlideOver;
  @service session;

  @task
  *loadLongLivedBasketTask(clientId, appointmentIds, currentDate) {
    let date = currentDate ?? today();
    let terminalId = yield this.paymentSlideOver.getTerminalId.perform();
    let response = yield this.apollo.mutate(
      {
        mutation: LongLivedBasket,
        variables: {
          clientId,
          date,
          appointmentIds,
          terminalId,
          staffMemberId: this.session.currentStaffMemberId,
        },
        fetchPolicy: 'network-only',
      },
      'longLivedBasket'
    );
    return response.basket;
  }

  @task
  *loadLongLivedBasketWithTodayAppointmentsTask(clientId) {
    let appointmentsIds =
      yield this.loadTodayAppointmentsForClientTask.perform(clientId);
    return yield this.loadLongLivedBasketTask.perform(
      clientId,
      appointmentsIds
    );
  }

  @task
  *loadTodayAppointmentsForClientTask(clientId) {
    if (!clientId) return [];
    let appointments = yield fetchAllPaginatedResults(
      this.apollo,
      {
        query: AppointmentIdsByDate,
        variables: {
          filterBy: {
            clientId,
            startDate: today(),
            endDate: today(),
          },
        },
        fetchPolicy: 'network-only',
      },
      'appointments'
    );
    if (appointments.length) {
      return appointments.map((a) => a.id);
    } else {
      return [];
    }
  }
}
