import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class PinPadRoute extends Route {
  @service autoLogout;
  @service router;
  @service('browser/local-storage') localStorage;

  redirect(model, transition) {
    if (!transition.from && transition.sequence === 0) {
      this.router.replaceWith('accounts');
    } else {
      this.autoLogout.isLocked = true;
    }
  }
}
