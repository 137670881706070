import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class SelectedNotePrinterService extends Service {
  @service session;
  @service electronApp;
  @service router;
  @service('browser/window') window;

  @tracked isPrinting = false;
  @tracked clientId;
  @tracked date;
  @tracked branchId;

  @action
  print({ clientId, date, branchId }) {
    this.clientId = clientId;
    this.date = date;
    this.branchId = branchId;
    if (this.electronApp.isRunningInElectron) {
      const path = this.router.urlFor(
        'accounts.account.print.selected-note',
        this.session.currentAccountId,
        clientId,
        date,
        branchId
      );
      const { protocol, host } = this.window.location;
      const printURL = new URL(`${protocol}//${host}${path}`);
      return this.electronApp.printReceipt(
        printURL.toString(),
        {
          successCb: () => {
            this.isPrinting = false;
          },
        },
        {
          waitForDataLoadedAttr: true,
        }
      );
    } else {
      this.isPrinting = true;
    }
  }

  @action
  onDone() {
    this.isPrinting = false;
  }
}
