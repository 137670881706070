import Component from '@glimmer/component';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';

export default class PinPadComponent extends Component {
  @service session;
  @service access;
  @service router;
  @service clockInOut;
  @service autoLogout;
  @service('browser/local-storage') localStorage;

  constructor() {
    super(...arguments);
    this.openPinPad.perform();
  }

  @dropTask
  *openPinPad() {
    yield this.access.openPinPadAndSwitchUser({
      cancelDisabled: true,
      onCancel: this.openPinPad.perform,
      hideBackground: true,
    });

    this.autoLogout.isLocked = false;
    if (
      this.session.branch.staffMemberSettings?.clockInOutEnabled &&
      !this.args.disableClockIn
    ) {
      this.clockInOut.checkClockInOut(this.session.currentStaffMember, {
        showOnlyWhenClockedOut: true,
      });
    }

    this.goToNextScreen();
  }

  goToNextScreen() {
    if (this.args.targetPath) {
      return this.router.replaceWith(this.args.targetPath);
    }
    const { attemptedTransition } = this.session;

    if (attemptedTransition) {
      this.session.attemptedTransition = null;

      attemptedTransition.retry();
    } else {
      this.router.replaceWith('accounts');
    }
  }
}
