import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';
import getBranchById from 'my-phorest/gql/queries/branch-by-id.graphql';
import getUserById from 'my-phorest/gql/queries/user.graphql';

export default class AccountsRoute extends Route {
  @service errorHandler;
  @service session;
  @service router;
  @service autoLogout;
  @service access;
  @service notifications;
  @service intl;
  @service('browser/local-storage') localStorage;

  @queryManager apollo;

  loginRoute = 'login';
  logoutRoute = 'logout';

  async beforeModel(transition) {
    const { isAuthenticated, isTerminal, userId } = this.session;

    const { queryParams } = transition.to;
    if (queryParams.embeddedRevision) {
      this.session.embeddedRevision = queryParams.embeddedRevision;
    }

    if (!isAuthenticated) {
      this.session.attemptedTransition = transition;

      return this.router.transitionTo(this.loginRoute);
    }

    if (isTerminal && !userId) {
      this.session.attemptedTransition = transition;

      return this.router.transitionTo('pin-pad');
    }

    if (this.#shouldRedirectToPinPad()) {
      return this.router.transitionTo('pin-pad');
    }
  }

  async model() {
    const { globalInfo, isGlobalUser, userId } = this.session;

    if (isGlobalUser) {
      return await this.getBranchById(globalInfo.branchId);
    } else {
      return await this.getUserById(userId);
    }
  }

  afterModel() {
    if (this.session.requireLoginPermissionCheck) {
      if (!this.access.hasPermission('login')) {
        this.notifications.failure(
          this.intl.t('login.no-permission.title'),
          this.intl.t('login.no-permission.message'),
          { sticky: true }
        );
        this.router.transitionTo(this.logoutRoute);
      }
      this.session.requireLoginPermissionCheck = false;
    }
  }

  async getUserById(userId) {
    try {
      let data = await this.apollo.query(
        {
          query: getUserById,
          variables: {
            id: userId,
          },
        },
        'user'
      );
      await this.session.newUser(data);
      return data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      this.router.transitionTo(this.logoutRoute);
      this.errorHandler.handle(error, { showError: false });
    }
  }

  async getBranchById(branchId) {
    try {
      let data = await this.apollo.query(
        {
          query: getBranchById,
          variables: {
            id: branchId,
          },
        },
        'branch'
      );
      await this.session.newGlobalUser(data);
      return data;
    } catch (error) {
      console.error('Error fetching branch data:', error);
      this.router.transitionTo(this.logoutRoute);
      this.errorHandler.handle(error, { showError: false });
    }
  }

  #shouldRedirectToPinPad() {
    return this.autoLogout.isLocked;
  }
}
