/* import __COLOCATED_TEMPLATE__ from './index.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class UiInputPercentWithKeypadComponent extends Component {
  @service keypad;
  @service intl;
  @service percentageFormatter;

  get formattedPercentage() {
    const { value } = this.args;
    if (!value) return '';

    return this.percentageFormatter.format(value, this.args.decimalPlaces ?? 3);
  }

  @action
  openKeypad() {
    this.keypad.openKeypad({
      value: this.args.value,
      headerText: this.intl.t('global.percentage'),
      required: true,
      variant: 'percent',
      allowNull: this.args.allowNull,
      allowNegative: this.args.allowNegative,
      decimalPlaces: this.args.decimalPlaces,
      onChange: (amount) => this.args.onInput(amount),
    });
  }
}
