import { helper } from '@ember/component/helper';

const THRESHOLD = 150; /* 0-255 */

// based on https://awik.io/determine-color-bright-dark-using-javascript/
export function isDarkColor(color) {
  let r, g, b;

  if (typeof color !== 'string') {
    return false;
  }

  if (color.match(/^#(?:[0-9a-fA-F]{3}){1,2}$/)) {
    // If HEX --> Convert it to RGB: http://gist.github.com/983661
    color = +('0x' + color.slice(1).replace(color.length < 5 && /./g, '$&$&'));

    r = color >> 16;
    g = (color >> 8) & 255;
    b = color & 255;
  } else {
    return false;
  }

  // HSP equation from http://alienryderflex.com/hsp.html
  let hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  return hsp < THRESHOLD;
}

export default helper(function ([color]) {
  return isDarkColor(color);
});
