import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { BREAKPOINT_MD, isAboveBreakpoint } from 'my-phorest/utils/breakpoints';
import { service } from '@ember/service';

export default class PinPadController extends Controller {
  @service swingBridge;
  @service router;
  @service session;

  @tracked isSidebarOpen = isAboveBreakpoint(BREAKPOINT_MD);

  queryParams = ['targetPath', 'disableClockIn'];

  get isEmbeddedInSwing() {
    return this.swingBridge.isEmbeddedInSwing;
  }
}
