import { modifier } from 'ember-modifier';

export default modifier(
  function onScrollEnd(element, [action]) {
    let handler = function () {
      // The +1 is added to fix a bug in Chrome where the scroll sometimes doesn't get to the end of the container
      if (
        element.offsetHeight + element.scrollTop + 1 >=
        element.scrollHeight
      ) {
        action?.();
      }
    };
    element.addEventListener('scroll', handler);

    // Prevents scroll being pinned to the bottom and automatically loading all data
    element.style.overflowAnchor = 'none';

    if (element.scrollHeight <= element.clientHeight) {
      action?.();
    }

    return () => {
      element.removeEventListener('scroll', handler);
    };
  },
  { eager: false }
);
