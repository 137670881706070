import Service, { service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';

import receiptBranchSettingsQuery from 'my-phorest/gql/queries/receipt-branch-settings.graphql';

const flipProps = (obj) =>
  Object.fromEntries(Object.entries(obj).map((entry) => entry.reverse()));

class SettingsMapper {
  mapping = {
    showFutureAppointmentsAndCourses: 'printFutureAppointmentsAndCourses',
    showTreatCardPoints: 'printLoyaltyPoints',
    customTopMessage: 'header',
    customBottomMessage: 'footer',
  };
  reverseMapping;

  constructor() {
    this.reverseMapping = flipProps(this.mapping);
  }

  get(target, prop) {
    if (prop in target) return target[prop];
    if (this.mapping[prop] in target) return target[this.mapping[prop]];
    if (this.reverseMapping[prop] in target)
      return target[this.reverseMapping[prop]];

    return Reflect.get(...arguments);
  }
}

export default class ReceiptSettingsService extends Service {
  @service('browser/window') window;
  @service errorHandler;
  @service router;
  @service session;
  @service terminalLoader;

  @queryManager apollo;

  receiptSettings;

  async fetchReceiptSettings() {
    if (this.session.currentBranch.receiptSettingsExist) {
      const receiptSettings = await this.fetchReceiptBranchSettings();
      this.receiptSettings = new Proxy(
        receiptSettings ?? {},
        new SettingsMapper()
      );
      return this.receiptSettings;
    }

    if (this.session.terminalId) {
      const terminal = await this.fetchTerminal();
      this.receiptSettings = new Proxy(
        terminal?.receiptSettings ?? {},
        new SettingsMapper()
      );
      return this.receiptSettings;
    }

    return null;
  }

  async fetchTerminal() {
    try {
      return await this.terminalLoader.getTerminal.perform(
        this.session.terminalId
      );
    } catch (error) {
      this.errorHandler.handle(error, { showError: false });
    }
  }

  async fetchReceiptBranchSettings() {
    try {
      return await this.apollo.watchQuery(
        {
          query: receiptBranchSettingsQuery,
          variables: {
            id: this.session.currentBranch.receiptSettingsId,
            branchId: this.session.currentBranch.id,
          },
        },
        'receiptBranchSettings'
      );
    } catch (error) {
      this.errorHandler.handle(error, { showError: false });
    }
  }
}
