import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { convertToLocalDatetimeNoSeconds } from 'my-phorest/utils/local-datetime-helpers';

export default class CalendarSlotHoverService extends Service {
  @tracked isPopoverOpen = false;
  @tracked show = false;
  @tracked time;
  @tracked slotReference;
  @tracked appendSlotTo;

  @service fullCalendar;
  @service intl;

  @action
  handleMove(e) {
    this.handleMouseMove(e);
  }

  @action
  removeHoverEvent() {
    if (!this.isBlocked) {
      this.show = false;
    }
  }

  @action
  handleMouseMove(mouseEvent) {
    if (this.isBlocked) {
      return;
    }

    const { x, y } = mouseEvent;
    const allElements = document.elementsFromPoint(x, y);

    const element = allElements.find((el) =>
      el.classList.contains('fc-timegrid-slot-lane')
    );

    if (element) {
      if (this._hideHoverWhenIsAppointment(allElements)) {
        return;
      }
      this.#updateHoverTime(element, mouseEvent);
    }
  }

  get isBlocked() {
    return this.isPopoverOpen || this._isBlocked;
  }

  set isBlocked(value) {
    this._isBlocked = value;
  }

  get calendarApi() {
    return this.fullCalendar.calendarApi;
  }

  get slotDateTime() {
    return convertToLocalDatetimeNoSeconds(`${this.getDate()}T${this.time}`);
  }

  getDate() {
    return this.slotReference?.col?.attributes['data-date']?.value;
  }

  getResource() {
    let id = this.slotReference?.col?.attributes['data-resource-id']?.value;
    return id && this.calendarApi.getResourceById(id);
  }

  _getTime(el) {
    if (this._isRowLaneElement(el)) {
      return el.attributes['data-time']?.value;
    }
  }

  _isRowLaneElement(el) {
    return el && el.classList.contains('fc-timegrid-slot-lane');
  }

  _getHoverColumn(element, event) {
    let cols = document.querySelectorAll('.fc-col-header thead th');
    let columnsCount = cols.length;
    let columnWidth = element.clientWidth / columnsCount;
    let rect = element.getBoundingClientRect();
    let index = Math.floor((event.x - rect.x) / columnWidth);
    return cols.item(index);
  }

  _hideHoverWhenIsAppointment(elements) {
    if (
      elements.find((el) =>
        el.attributes.getNamedItem('data-calendar-appointment')
      )
    ) {
      this.removeHoverEvent();
      return true;
    }

    return false;
  }

  #updateHoverTime(row, mouseEvent) {
    let time = this._getTime(row);
    if (!time) {
      return;
    }

    this.appendSlotTo = document.querySelector(
      '[data-appointment-calendar-container] tbody .fc-timegrid-body'
    );
    let col = this._getHoverColumn(row, mouseEvent);
    this.slotReference = {
      col,
      row,
    };

    this.time = time;
    this.show = true;
  }
}
