/* eslint-disable phorest/moment-import */
import { fromJSDate as timeFromJSDate } from 'my-phorest/utils/local-time-helpers';
import { changeBrightness } from 'my-phorest/utils/color';
import moment from 'moment';
import { isDarkColor } from 'my-phorest/helpers/is-dark-color';

export const STATES = {
  checkedIn: 'CHECKED_IN',
  reserved: 'RESERVED',
  paid: 'PAID',
  late: 'LATE',
  noShow: 'NO_SHOW',
};
const COLORS = {
  [STATES.checkedIn]: '#FFFF00',
  [STATES.reserved]: '#FAFAFA',
  [STATES.paid]: '#68C668',
  [STATES.late]: '#FF4500',
  [STATES.noShow]: '#D3D3D3',
  newClientAppointment: '#7CBFEE',
  accountDepositAppointment: '#929200',
  waitlistHoldingSlot: '#FAFAFA',
};

export const NO_SHOW_COLOR = COLORS[STATES.noShow];

const BREAK_BACKGROUND = '#595959';
const LIGHT_TEXT = 'text-white';
const DARK_TEXT = 'text-primary';

export class CalendarEvent {
  constructor({
    id,
    startTime,
    endTime,
    durationMins,
    date,
    staffMember,
    staffMemberId,
    machine,
    room,
    resourceType,
  } = {}) {
    this.id = id;
    this.startTime = startTime;
    this.endTime = endTime;
    this.durationMins = durationMins;
    this.date = date;
    this.staffMemberId = staffMemberId;
    this.resourceType = resourceType;
    this.room = room;
    this.machine = machine;
    this.staffMember = staffMember;

    if ('Machine' === resourceType) {
      this.resourceId = `${resourceType}::${machine.id}`;
    } else if ('Room' === resourceType) {
      this.resourceId = `${resourceType}::${room.id}`;
    } else {
      this.resourceId = `${resourceType}::${staffMemberId}`;
    }
  }

  static fromData(data, view) {
    const { __typename } = data;
    if (__typename === 'Break') {
      return new BreakEvent(data, view);
    } else if (__typename === 'Appointment') {
      return new AppointmentEvent(data, view);
    } else {
      return new CalendarEvent(data, view);
    }
  }

  static fromFCEvent(event) {
    const { id, extendedProps, start, end } = event;
    const startTime = timeFromJSDate(start, moment.HTML5_FMT.TIME);
    const endTime = timeFromJSDate(end, moment.HTML5_FMT.TIME);
    const {
      isBreak,
      durationMins,
      date,
      machine,
      room,
      staffMember,
      staffMemberId,
      resourceType,
      isRecurrent,
    } = extendedProps;

    if (isBreak) {
      const { label } = extendedProps;
      return new BreakEvent({
        // Common fields
        id: id.split('::')[1],
        startTime,
        endTime,
        durationMins,
        date,
        machine,
        room,
        resourceType,
        staffMember,
        staffMemberId,
        // Break fields
        label,
        isRecurrent,
      });
    } else {
      const {
        client,
        confirmed,
        groupBookingId,
        groupBookingPrimaryClientId,
        machine,
        room,
        state,
        staffRequested,
        price,
        service,
        staffMember,
        flags,
        depositPaid,
        depositAmount,
        min24HourDepositPaid,
        recurrence,
        lastReminderSentTime,
        clientCourse,
        clientCourseItem,
        serviceGroup,
        serviceGroupId,
        serviceGroupItemOptionId,
        isReward,
        color,
      } = extendedProps;
      return new AppointmentEvent({
        // Common fields
        id: id.split('::')[1],
        startTime,
        endTime,
        durationMins,
        date,
        machine,
        resourceType,
        room,
        staffMemberId,
        // Appointment fields
        client,
        confirmed,
        groupBookingId,
        groupBookingPrimaryClientId,
        state,
        staffRequested,
        price,
        service,
        staffMember,
        flags,
        depositPaid,
        min24HourDepositPaid,
        depositAmount,
        recurrence,
        lastReminderSentTime,
        clientCourse,
        clientCourseItem,
        serviceGroup,
        serviceGroupId,
        serviceGroupItemOptionId,
        isReward,
        color,
      });
    }
  }

  toFCEvent() {
    const {
      id,
      backgroundColor,
      borderColor,
      resourceId,
      start,
      end,
      editable,
      resourceType,
      resourceEditable,
      startTime,
      endTime,
      durationMins,
      date,
    } = this;

    return {
      id,
      backgroundColor,
      borderColor,
      resourceId,
      start,
      end,
      editable,
      resourceEditable,
      extendedProps: {
        startTime,
        endTime,
        durationMins,
        date,
        resourceType,
      },
    };
  }

  get start() {
    return `${this.date}T${this.startTime}`;
  }

  get end() {
    return `${this.date}T${this.endTime}`;
  }

  get editable() {
    return true;
  }

  get resourceEditable() {
    return true;
  }

  get backgroundColor() {
    return '#808080';
  }

  get stateForDataAttribute() {
    return '';
  }

  get textColor() {
    return isDarkColor(this.backgroundColor) ? LIGHT_TEXT : DARK_TEXT;
  }

  get borderColor() {
    return changeBrightness(this.backgroundColor, -40);
  }

  get resource() {
    switch (this.resourceType) {
      case 'Staff':
        return {
          id: this.resourceId,
          extendedProps: this.staffMember,
        };
      case 'Machine':
        return {
          id: this.resourceId,
          extendedProps: this.machine,
        };
      case 'Room':
        return {
          id: this.resourceId,
          extendedProps: this.room,
        };
    }

    return null;
  }
}

export class AppointmentEvent extends CalendarEvent {
  constructor(options = {}, view) {
    super(options, view);
    const {
      client,
      customPrompts,
      confirmed,
      groupBookingId,
      groupBookingPrimaryClientId,
      state,
      staffRequested,
      price,
      service,
      staffMember,
      room,
      machine,
      flags,
      depositPaid,
      depositAmount,
      min24HourDepositPaid,
      recurrence,
      lastReminderSentTime,
      clientCourse,
      clientCourseItem,
      serviceGroup,
      serviceGroupId,
      serviceGroupItemOptionId,
      isReward,
      color,
    } = options;
    this.client = client;
    this.customPrompts = customPrompts;
    this.confirmed = confirmed;
    this.groupBookingId = groupBookingId;
    this.groupBookingPrimaryClientId = groupBookingPrimaryClientId;
    this.state = state;
    this.staffRequested = staffRequested;
    this.service = service;
    this.price = price;
    this.staffMember = staffMember;
    this.room = room;
    this.machine = machine;
    this.flags = flags;
    this.depositPaid = depositPaid;
    this.depositAmount = depositAmount;
    this.min24HourDepositPaid = min24HourDepositPaid;
    this.recurrence = recurrence;
    this.lastReminderSentTime = lastReminderSentTime;
    this.clientCourse = clientCourse;
    this.clientCourseItem = clientCourseItem;
    this.serviceGroup = serviceGroup;
    this.serviceGroupId = serviceGroupId;
    this.serviceGroupItemOptionId = serviceGroupItemOptionId;
    this.isReward = isReward;
    this.color = color;
  }

  toFCEvent() {
    let event = super.toFCEvent();
    const {
      service,
      serviceName,
      customPrompts,
      client,
      clientId,
      clientName,
      groupBookingId,
      groupBookingPrimaryClientId,
      machine,
      room,
      mobile,
      staffRequested,
      staffMember,
      staffMemberId,
      confirmed,
      state,
      backgroundColor,
      price,
      flags,
      depositPaid,
      depositAmount,
      min24HourDepositPaid,
      recurrence,
      lastReminderSentTime,
      clientCourse,
      clientCourseItem,
      serviceGroup,
      serviceGroupId,
      serviceGroupItemOptionId,
      isReward,
      color,
    } = this;

    return {
      ...event,
      id: `Appointment::${event.id}`,
      extendedProps: {
        ...event.extendedProps,
        service,
        serviceColor: backgroundColor,
        serviceName,
        customPrompts,
        client,
        clientId,
        clientName,
        groupBookingId,
        groupBookingPrimaryClientId,
        machine,
        room,
        mobile,
        staffMember,
        staffMemberId,
        staffRequested,
        confirmed,
        state,
        price,
        flags,
        depositPaid,
        depositAmount,
        min24HourDepositPaid,
        recurrence,
        lastReminderSentTime,
        clientCourse,
        clientCourseItem,
        serviceGroup,
        serviceGroupId,
        serviceGroupItemOptionId,
        isReward,
        color,
      },
    };
  }

  get editable() {
    return !this.isReserved;
  }

  get resourceEditable() {
    return !this.isReserved;
  }

  get backgroundColor() {
    if (this.isWaitlistHoldingSlot) {
      return COLORS.waitlistHoldingSlot;
    }

    if (
      this.isReserved ||
      this.isCheckedIn ||
      this.isPaid ||
      this.isLate ||
      this.isNoShow
    ) {
      return COLORS[this.state];
    }

    if (this.showAccountDepositAppointmentColor) {
      return COLORS.accountDepositAppointment;
    }

    if (this.showNewClientAppointmentColor) {
      return COLORS.newClientAppointment;
    }

    return this.color?.hex;
  }

  get textColor() {
    if (this.isPaid) {
      return DARK_TEXT;
    } else {
      return super.textColor;
    }
  }

  get isCheckedIn() {
    return this.state === STATES.checkedIn;
  }

  get isReserved() {
    return this.state === STATES.reserved;
  }

  get isPackageAppointment() {
    return !!this.serviceGroupId;
  }

  get isSpecialOfferAppointment() {
    return !!this.serviceGroup?.isSpecialOffer;
  }

  get isPaid() {
    return this.state === STATES.paid;
  }

  get isLate() {
    return this.state === STATES.late;
  }

  get isNoShow() {
    return this.state === STATES.noShow;
  }

  get isWaitlistHoldingSlot() {
    return this.flags.includes('IS_WAITLIST_HOLDING_SLOT');
  }

  get showNewClientAppointmentColor() {
    return this.flags.includes('SHOW_NEW_CLIENT_APPOINTMENT_COLOR');
  }

  get showAccountDepositAppointmentColor() {
    return (
      this.depositAmount?.amount && parseInt(this.depositAmount?.amount) !== 0
    );
  }

  get canBeMadeLate() {
    return !(
      this.isLate ||
      this.isPaid ||
      this.isCheckedIn ||
      this.isWaitlistHoldingSlot
    );
  }

  get canBeMadeNoShow() {
    return !(
      this.isNoShow ||
      this.isPaid ||
      this.isCheckedIn ||
      this.isWaitlistHoldingSlot
    );
  }

  get stateForDataAttribute() {
    return this.state.toLowerCase().replace('_', '-');
  }

  get clientId() {
    return this.client?.id;
  }

  get clientName() {
    const { client } = this;
    return client?.name;
  }

  get mobile() {
    return this.client?.mobile;
  }

  get serviceName() {
    return this.service?.name;
  }

  get staffMemberName() {
    const { staffMember } = this;
    return staffMember.name;
  }
}

export class BreakEvent extends CalendarEvent {
  constructor(options = {}, view) {
    super(options, view);
    const { staffMember, staffMemberId, machine, room, label, isRecurrent } =
      options;
    this.staffMember = staffMember;
    this.staffMemberId = staffMemberId;
    this.machine = machine;
    this.room = room;
    this.label = label;
    this.isBreak = true;
    this.isRecurrent = isRecurrent;
  }

  toFCEvent() {
    let event = super.toFCEvent();
    const { machine, room, staffMemberId, serviceName, isBreak, isRecurrent } =
      this;

    return {
      ...event,
      id: `Break::${event.id}`,
      extendedProps: {
        ...event.extendedProps,
        machine,
        room,
        staffMemberId,
        isBreak,
        staffMember: this.staffMember,
        serviceName,
        label: serviceName,
        isRecurrent,
      },
    };
  }

  get serviceName() {
    return this.label;
  }

  get backgroundColor() {
    return BREAK_BACKGROUND;
  }
}
