import Service, { service } from '@ember/service';
import { action } from '@ember/object';
import * as Sentry from '@sentry/browser';
import { jwtDestructure } from 'my-phorest/utils/auth';
import { tracked } from '@glimmer/tracking';
import { registerDestructor } from '@ember/destroyable';

const DEFAULT_HATCH_FF_VALUE = 'DISABLED';
const IS_EMBEDDED_SESSION_STORAGE_KEY = 'is-embedded-in-swing';
const NO_ESCAPE_HATCH_FOH_FF_VALUE = 'DEFAULT_TO_BROWSER_NO_ESCAPE_HATCH';
const NO_ESCAPE_HATCH_BOH_FF_VALUE = 'ENABLED_NO_ESCAPE_HATCH';
const SWING_HOST_IDENTIFIER = 'swing';

/*
  Although, the web team would love to kill this whole service, the list/map of handlers might grow.

  We are basing this on `swing-aware`. We didn't copy all available "hooks into Swing",
  but let this list be an inspiration for you:
  https://github.com/phorest/phorest-desktop-ember/blob/0739c2639644746e0c6d2a5d832ae0403658689a/app/services/bridge-to-swing.js#L114-L297

  If you need any other escape hatch and redirect a user to a place in Swing,
  first verify if that's not already supported by Swing. You'll then just steal
  a `case` statement from the code above.
*/
const SECTIONS_INFO = {
  appointments: {
    flag: 'release-embedded-appointment-screen',
    handler: 'appointmentEscapeHatch',
    noEscapeHatchValue: NO_ESCAPE_HATCH_FOH_FF_VALUE,
  },
  business: {
    flag: 'release-embedded-business-screen',
    handler: 'businessButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  cashUp: {
    flag: 'release-embedded-cash-up-screen',
    handler: 'cashupButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  chainCourses: {
    flag: 'release-embedded-courses-screen',
    handler: 'courseLibraryButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  chainMemberships: {
    flag: 'release-embedded-memberships-screen',
    handler: 'membershipLibraryButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  chainProducts: {
    flag: 'release-embedded-products-screen',
    handler: 'productLibraryButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  chainPackages: {
    flag: 'release-embedded-packages-screen',
    handler: 'packageLibraryButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  chainSpecialOffers: {
    flag: 'release-embedded-special-offers-screen',
    handler: 'specialOfferLibraryButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  clientMerge: {
    flag: 'release-embedded-client-merge-screen',
    handler: 'clientMergeButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  clients: {
    flag: 'release-embedded-client-screen',
    handler: 'clientEscapeHatch',
    noEscapeHatchValue: NO_ESCAPE_HATCH_FOH_FF_VALUE,
  },
  commission: {
    flag: 'release-embedded-commissions-screen',
    handler: 'commissionButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  clientReconnect: {
    flag: 'release-embedded-client-reconnect-screen',
    handler: 'clientEngagementButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  courses: {
    flag: 'release-embedded-courses-screen',
    handler: 'coursesButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  discounts: {
    flag: 'release-embedded-discounts-screen',
    handler: 'discountsButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  memberships: {
    flag: 'release-embedded-memberships-screen',
    handler: 'membershipsButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  orders: {
    flag: 'release-embedded-orders-screen',
    handler: 'ordersButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  packages: {
    flag: 'release-embedded-packages-screen',
    handler: 'packagesButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  products: {
    flag: 'release-embedded-products-screen',
    handler: 'stockButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  purchase: {
    flag: 'release-embedded-purchase-screen',
    handler: 'purchaseEscapeHatch',
    noEscapeHatchValue: NO_ESCAPE_HATCH_FOH_FF_VALUE,
  },
  sales: {
    flag: 'release-embedded-sales-screen',
    handler: 'salesButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  stockTakes: {
    flag: 'release-embedded-stock-takes-screen',
    handler: 'stockTakesButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  stockTransfers: {
    flag: 'release-embedded-stock-transfers-screen',
    handler: 'stockTransfersButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  specialOffers: {
    flag: 'release-embedded-special-offers-screen',
    handler: 'specialOffersButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  suppliers: {
    flag: 'release-embedded-suppliers-screen',
    handler: 'suppliersButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  taxRates: {
    flag: 'release-embedded-tax-rates-screen',
    handler: 'vatTaxButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
  vouchers: {
    flag: 'release-embedded-vouchers-screen',
    handler: 'vouchersButton',
    noEscapeHatchValue: NO_ESCAPE_HATCH_BOH_FF_VALUE,
  },
};

class SwingBridgeError extends Error {
  name = 'SwingBridge';
}

export default class SwingBridgeService extends Service {
  @service access;
  @service('browser/session-storage') sessionStorage;
  @service('browser/window') window;
  @service notifications;
  @service intl;

  @tracked swingVersion = null;
  @tracked terminalId = null;

  get isEmbeddedInSwing() {
    return (
      this.sessionStorage.getItem(IS_EMBEDDED_SESSION_STORAGE_KEY) === 'true'
    );
  }
  set isEmbeddedInSwing(value) {
    this.sessionStorage.setItem(IS_EMBEDDED_SESSION_STORAGE_KEY, value);
  }

  detectSwing(
    host,
    { accessToken, globalInfo, hostVersion } = { hostVersion: null }
  ) {
    const isEmbeddedInSwing = host === SWING_HOST_IDENTIFIER;
    this.isEmbeddedInSwing = isEmbeddedInSwing;

    if (isEmbeddedInSwing) {
      this.swingVersion = hostVersion;
      this.exposeUserSwapByPin();
      this.#persistTerminalId(accessToken, globalInfo);
    }
    return this.isEmbeddedInSwing;
  }

  exposeUserSwapByPin() {
    if (!this.window.phorestUserSwapByPin) {
      this.window.phorestUserSwapByPin = async (pin) => {
        Sentry.addBreadcrumb({
          category: 'Swing',
          message: `Staff member swapped via Swing's PIN-pad`,
          level: 'info',
        });
        return this.access.handlePin.perform(pin, { notifySwing: false });
      };
      registerDestructor(this, () => {
        this.window.phorestUserSwapByPin = undefined;
      });
    }
  }

  logError(errorMessage) {
    console.warn(errorMessage);
    Sentry.captureException(new SwingBridgeError(errorMessage));
  }

  @action
  navigateTo(section) {
    const navigationAction = SECTIONS_INFO[section]?.handler;

    if (!navigationAction) {
      const nonSupportedSection = `Unexpected: a section used with swingBridge.navigateTo() ("${section}") is not supported`;
      this.logError(nonSupportedSection);
      return;
    }

    if (!this.#isEscapeHatchAvailable(navigationAction)) {
      return;
    }

    this.window.navigationHandler[navigationAction]();
  }

  notifyLoggedInStaffChanged(pin) {
    try {
      this.window?.securityHandler?.newStaffLoginWithPin(pin);
    } catch (e) {
      const message = `Unexpected: we tried to notify Swing about staff change but the window.securityHandler.newStaffLoginWithPin wasn't there`;
      this.logError(message);
    }
  }

  shouldShowEscapeHatch(section) {
    if (!this.isEmbeddedInSwing) return false;

    const sectionInfo = SECTIONS_INFO[section];
    if (!this.#isEscapeHatchAvailable(sectionInfo.handler)) return false;

    const flagValue = this.#getSwingAwareFlagValue(sectionInfo.flag);
    const { noEscapeHatchValue } = sectionInfo;
    return flagValue !== noEscapeHatchValue;
  }

  canOpenExternalBrowser() {
    try {
      if (this.isEmbeddedInSwing) {
        window.navigationHandler.openInExternalBrowser('');
      }
      return true;
    } catch (error) {
      this.logError(error);
      return false;
    }
  }

  openInExternalBrowser(url) {
    if (this.canOpenExternalBrowser) {
      if (this.isEmbeddedInSwing) {
        window.navigationHandler.openInExternalBrowser(url);
      } else {
        this.window.open(url);
      }
    } else {
      this.notifications.failure(
        this.intl.t('global.error-notification-title'),
        '',
        { sticky: true }
      );
    }
  }

  openInSwingEmbedded(url) {
    if (this.#canOpenInSwingEmbedded) {
      this.window.navigationHandler.openInSwingEmbedded(url);
    } else {
      this.notifications.failure(
        this.intl.t('global.error-notification-title'),
        '',
        { sticky: true }
      );
    }
  }

  #canOpenInSwingEmbedded() {
    try {
      if (
        this.isEmbeddedInSwing &&
        typeof this.window.navigationHandler?.openInSwingEmbedded === 'function'
      ) {
        return true;
      }
      return false;
    } catch (error) {
      this.logError(`Cannot open in swing embedded: ${error}`);
      return false;
    }
  }

  invalidateBranchSettingsCache() {
    this.#callInvalidateCacheFunction('invalidateBranchSettings');
  }

  #getSwingAwareFlagValue(flagName) {
    try {
      return this.window?.featureFlagHandler?.getValue(flagName);
    } catch (e) {
      // fail gracefully
      return DEFAULT_HATCH_FF_VALUE;
    }
  }

  #isEscapeHatchAvailable(functionName) {
    try {
      return (
        typeof this.window?.navigationHandler?.[functionName] !== 'undefined'
      );
    } catch (e) {
      if (this.swingVersion) {
        const message = `Unexpected: escape hatch is not available ("${functionName}")`;
        this.logError(message);
      }

      // fail gracefully
      return false;
    }
  }

  #persistTerminalId(accessToken, globalInfo) {
    if (typeof globalInfo?.terminalId !== 'undefined') {
      this.terminalId = globalInfo.terminalId;
    } else {
      let { user_id: userId, client_type: clientType } =
        jwtDestructure(accessToken);

      if (clientType === 'terminal') {
        this.terminalId = userId;
      }
    }
  }

  #callInvalidateCacheFunction(funcName) {
    if (!this.isEmbeddedInSwing) return;

    try {
      let invalidateFunc = this.window.cacheHandler[funcName];
      invalidateFunc();
    } catch (error) {
      const cacheInvalidationFailed = `Swing cache invalidation failed for "${funcName}"`;
      this.logError(cacheInvalidationFailed);
    }
  }
}
