import { addMoney } from 'my-phorest/utils/currency';
import { tracked } from '@glimmer/tracking';
const { entries } = Object;

class StaffMemberTip {
  @tracked staffMember;
  @tracked tipAmount;

  constructor({ staffMember, tipAmount = 0 } = {}) {
    this.staffMember = staffMember;
    this.tipAmount = tipAmount;
  }

  get isValid() {
    return (
      this.staffMember &&
      this.tipAmount &&
      this.tipAmount !== '-' &&
      !isNaN(this.tipAmount)
    );
  }
}

export default class StaffMemberTips {
  @tracked tips;

  constructor(tips) {
    this.tips = tips.map(
      ({ staffMember, tipAmount }) =>
        new StaffMemberTip({
          staffMember,
          tipAmount: tipAmount?.amount,
        })
    );
  }

  appendEmptyTip() {
    this.tips = [...this.tips, new StaffMemberTip()];
  }

  removeTip(tip) {
    let toRemoveIndex = this.tips.indexOf(tip);
    this.tips.splice(toRemoveIndex, 1);
    this.tips = [...this.tips];
  }

  serialize() {
    let removeNotFilled = (i) => Number(i.tipAmount) !== 0 && i.staffMember?.id;
    let unifyById = (acc, curr) => {
      let staffMemberId = curr.staffMember.id;
      acc[staffMemberId] = addMoney(acc[staffMemberId] || 0, curr.tipAmount);
      return acc;
    };

    return entries(this.tips.filter(removeNotFilled).reduce(unifyById, {})).map(
      ([staffMemberId, tipAmount]) => {
        return {
          staffMemberId,
          tipAmount,
        };
      }
    );
  }

  get total() {
    return this.tips?.reduce((acc, curr) => {
      return addMoney(acc, curr.tipAmount);
    }, 0);
  }
}
