import Service, { service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';
import { dropTask } from 'ember-concurrency';

import tillSessionCombinedReportQuery from 'my-phorest/gql/queries/till-session-combined-report.graphql';
import tillsQuery from 'my-phorest/gql/queries/tills.graphql';
import { fetchAllPaginatedResults } from 'my-phorest/utils/graphql';

export default class CashUpReportService extends Service {
  @service('browser/window') window;
  @service electronApp;
  @service session;
  @service router;

  @queryManager apollo;

  @dropTask
  *fetchReportDataTask(tillId, sessionId) {
    const tillReport = yield this.apollo.query(
      {
        query: tillSessionCombinedReportQuery,
        variables: {
          tillId,
          sessionId,
        },
      },
      'tillSessionCombinedReport'
    );

    const tills = yield this.fetchTillsTask.perform();

    const groupedReport = this.groupReportData(
      tillReport.tillSessionReport.reportLines
    );

    return {
      ...groupedReport,
      countDateTime: tillReport?.countDateTime,
      countingStaffName: tillReport?.countingStaff?.name,
      tillName: tills[tillId]?.name,
      tillSessionReport: tillReport.tillSessionReport,
    };
  }

  @dropTask
  *fetchTillsTask() {
    const tills = yield fetchAllPaginatedResults(
      this.apollo,
      {
        query: tillsQuery,
        variables: {
          first: 500,
        },
      },
      'tills'
    );
    return Object.fromEntries(tills.map((t) => [t.id, t]));
  }

  groupReportData(reportLines) {
    const groupingKeys = [
      'estimatedOther',
      'actualOther',
      'otherBalanceDifference',
    ];

    let groupedReport = {
      estimatedOther: [],
      actualOther: [],
      otherBalanceDifference: [],
    };

    reportLines.forEach((item) => {
      for (const key of groupingKeys) {
        if (item.lineKey.includes(key)) {
          groupedReport[key].push(item);
          return;
        }
      }
    });

    return groupedReport;
  }

  printReportInElectron(tillId, sessionId, { successCb } = {}) {
    if (!this.electronApp.isRunningInElectron) return;
    let path;

    try {
      path = this.router.urlFor(
        'accounts.account.cash-up-report',
        this.session.currentAccountId,
        tillId
      );
    } catch (error) {
      console.warn(
        `Issue with generating report link. Params: accountId: "${this.session.currentAccountId}", cash up: "${tillId}"`
      );
      return this.errorHandler.handle(error);
    }

    const { protocol, host } = this.window.location;
    const receiptURL = new URL(`${protocol}//${host}${path}`);

    receiptURL.searchParams.append('sessionId', sessionId);

    return this.electronApp.printReceipt(receiptURL.toString(), { successCb });
  }
}
