import { format as formatDate } from 'my-phorest/utils/local-date-helpers';
import { format as formatTime } from 'my-phorest/utils/local-time-helpers';

export function formatPurchaseDescription(purchase, intl) {
  const { voidedPurchase, voidingPurchase, zeroPurchaseType } = purchase;

  if (zeroPurchaseType) {
    return intl.t(
      `sales.till-transactions.description.-DYNAMIC-receipt-type.${zeroPurchaseType}`
    );
  }

  if (voidedPurchase) {
    return intl.t('sales.till-transactions.description.voiding', {
      date: formatPurchaseDateTime(voidedPurchase, intl),
    });
  }

  if (voidingPurchase) {
    return intl.t('sales.till-transactions.description.voided', {
      date: formatPurchaseDateTime(voidingPurchase, intl),
    });
  }

  if (purchase.isPartialRefund) {
    return intl.t('sales.till-transactions.description.partial-refund');
  }

  if (purchase.isFullRefund) {
    return intl.t('sales.till-transactions.description.refund');
  }

  return intl.t('sales.till-transactions.description.sale');
}

export function formatPurchaseDateTime(purchase, intl) {
  const { purchasedAtDateTime: dateTime } = purchase;
  let [, time] = dateTime.split('T');
  let dateShort = formatDate(dateTime, intl, 'tiny');
  let formattedTime = formatTime(time, intl);
  return `${dateShort} ${formattedTime}`;
}

function _paymentAbbreviation(payment, intl) {
  const formattedAmount = intl.formatNumber(payment.amount ?? 0, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return `${payment.code}(${formattedAmount})`;
}

export function formatPurchasePaymentsAbbreviation(purchase, intl) {
  const changeAmount = Number(purchase.changeAmount.amount);
  let payments = purchase.payments;
  if (changeAmount !== 0) {
    let cashPayment = purchase.payments.find(
      (payment) => payment.type === 'CASH'
    );
    if (cashPayment) {
      cashPayment = {
        ...cashPayment,
        amount: cashPayment.amount - changeAmount,
      };
      payments = [
        cashPayment,
        ...purchase.payments.filter((payment) => payment.type !== 'CASH'),
      ];
    } else {
      const cashCode = intl.t('sales.till-transactions.payment-codes.CASH');
      payments = [
        { type: 'CASH', code: cashCode, amount: -changeAmount },
        ...purchase.payments,
      ];
    }
  }

  const abbreviations = payments.map((payment) =>
    _paymentAbbreviation(payment, intl)
  );
  return abbreviations.join(',');
}
