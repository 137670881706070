import Service from '@ember/service';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import config from 'my-phorest/config/environment';

export default class onlineReputationService extends Service {
  @service session;
  @service('browser/window') window;

  @tracked isOnlineRepBooted = false;

  get isFinishSpeakingBranch() {
    return this.session.locale.lang === 'fi';
  }

  get isGermanSpeakingBranch() {
    return this.session.locale.lang === 'de';
  }

  get isOnlineRepEnabled() {
    return this.session.business.marketing.onlineReputationEnabled;
  }

  get onlineRepOrigin() {
    return this.session.silo === 'us'
      ? config.onlineRepOriginUS
      : config.onlineRepOriginEU;
  }

  get onlineRepUrl() {
    if (this.isOnlineRepEnabled) {
      let onlineRepData = new URLSearchParams({
        branch_link: this.#buildOnlineRepBranchLink(),
        use_internal_browser: true, // needed so page has access to current auth session in Electron
      });

      return `${this.onlineRepOrigin}/online-reputation-frontend/bootstrap/?${onlineRepData}`;
    } else {
      return this.linkToTypeform();
    }
  }

  @action
  linkToTypeform() {
    if (this.isGermanSpeakingBranch) {
      return `https://phorestsalonsoftware.typeform.com/to/pVTZLPr2?utm_source=in_product&utm_campaign=onlinerep_button#oakid=${this.session.accountId}`;
    } else if (this.isFinishSpeakingBranch) {
      return `https://phorestsalonsoftware.typeform.com/to/ixJGmVwL?utm_source=in_product&utm_campaign=onlinerep_button#oakid=${this.session.accountId}`;
    } else {
      return `https://phorestsalonsoftware.typeform.com/to/phaXjVxY?utm_source=in_product&utm_campaign=onlinerep_button#oakid=${this.session.accountId}`;
    }
  }

  @action
  openOnlineRepTab() {
    let onlineRepTab = this.window.open(this.onlineRepUrl, '_blank');

    this.window.addEventListener('message', this.handleMessageFromOnlineRep);

    let waitForOnlineRepAppToBoot = () => {
      if (!this.isOnlineRepBooted) {
        onlineRepTab.postMessage(
          { command: 'browserBootingOnlineRep' },
          this.onlineRepOrigin
        );
      } else {
        clearInterval(interval);
        this.isOnlineRepBooted = false;
      }
    };

    let interval = setInterval(waitForOnlineRepAppToBoot, 300);
  }

  @action
  handleMessageFromOnlineRep(event) {
    let { origin, source } = event;
    let { command } = event.data;

    if (origin === this.onlineRepOrigin && command === 'onlineRepDidBoot') {
      let basicToken = this.session.pToken;
      let bearerToken = this.session.accessToken;
      let refreshToken = this.session.refreshToken;
      let securityContext = this.session.securityScope;

      this.isOnlineRepBooted = true;

      source.postMessage(
        {
          basicToken,
          bearerToken,
          refreshToken,
          securityContext,
          command: 'onlineReputationToken',
        },
        event.origin
      );

      this.removeEventListener();
    }
  }

  removeEventListener() {
    this.window.removeEventListener('message', this.handleMessageFromOnlineRep);
  }

  #buildOnlineRepBranchLink() {
    const {
      branch: { id: branchId },
      business: { id: businessId },
    } = this.session;
    return `${this.onlineRepOrigin}/memento/rest/business/${businessId}/branch/${branchId}`;
  }
}
