import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { queryManager } from 'ember-apollo-client';
import voidPurchaseMutation from 'my-phorest/gql/mutations/void-purchase.graphql';
import refundPurchaseMutation from 'my-phorest/gql/mutations/refund-purchase.graphql';
import editPurchaseMutation from 'my-phorest/gql/mutations/edit-purchase.graphql';
import supervisorDeletePurchaseMutation from 'my-phorest/gql/mutations/supervisor-delete-purchase.graphql';
import makeComplimentaryPurchaseMutation from 'my-phorest/gql/mutations/make-complimentary-purchase.graphql';
import updatePurchaseMutation from 'my-phorest/gql/mutations/update-purchase.graphql';
import checkSupervisorDeleteAllowedQuery from 'my-phorest/gql/queries/check-supervisor-delete-allowed.graphql';
import checkMakeComplimentaryAllowedQuery from 'my-phorest/gql/queries/check-make-complimentary-allowed.graphql';
import { dropTask } from 'ember-concurrency';
import { service } from '@ember/service';
import { permission } from 'my-phorest/decorators/permission';

const INITIAL_SCREEN = 'transaction-details';

export default class TransactionSlideOverService extends Service {
  @queryManager apollo;

  @service paymentSlideOver;
  @service router;

  @tracked purchase;

  @tracked isOpen = false;
  @tracked screen = INITIAL_SCREEN;

  @tracked isLoadingReceiptData = false;
  @tracked voidPurchaseModalOpen = false;
  @tracked supervisorDeleteCheck = null;
  @tracked supervisorDeleteModalOpen = false;
  @tracked showReceiptOptionsModal = false;

  @tracked makeComplimentaryCheck = null;
  @tracked makeComplimentaryModalOpen = false;
  @tracked complimentarySaleType = null;

  async openWithPurchase(purchase) {
    this.purchase = purchase;
    this.screen = INITIAL_SCREEN;
    this.isOpen = true;
  }

  close() {
    this.isOpen = false;
  }

  get isVoid() {
    return this.purchase.voidingPurchase || this.purchase.voidedPurchase;
  }

  @dropTask
  *voidPurchase(voidReason) {
    yield this.apollo.mutate(
      {
        mutation: voidPurchaseMutation,
        variables: {
          input: {
            purchaseId: this.purchase.id,
            voidReason,
          },
        },
      },
      'voidPurchase'
    );
  }

  @dropTask
  *refundPurchaseTask() {
    const terminalId = yield this.paymentSlideOver.getTerminalId.perform();
    const { basket } = yield this.apollo.mutate(
      {
        mutation: refundPurchaseMutation,
        variables: {
          purchaseId: this.purchase.id,
          terminalId,
        },
      },
      'refundPurchase'
    );

    this.paymentSlideOver.returnToSalesEnabled = true;
    this.router.transitionTo('accounts.account.purchase.basket', basket.id);
    this.close();
  }

  @dropTask
  @permission('sales.till-transactions.edit')
  *editPurchaseTask() {
    const terminalId = yield this.paymentSlideOver.getTerminalId.perform();
    const { basket } = yield this.apollo.mutate(
      {
        mutation: editPurchaseMutation,
        variables: {
          purchaseId: this.purchase.id,
          terminalId,
        },
      },
      'editPurchase'
    );

    this.paymentSlideOver.returnToSalesEnabled = true;
    this.router.transitionTo('accounts.account.purchase.basket', basket.id);
    this.close();
  }

  @dropTask
  *checkSupervisorDeleteAllowed() {
    this.supervisorDeleteCheck = yield this.apollo.query(
      {
        query: checkSupervisorDeleteAllowedQuery,
        variables: {
          purchaseId: this.purchase.id,
        },
      },
      'checkSupervisorDeleteAllowed'
    );
    this.supervisorDeleteModalOpen = true;
  }

  @dropTask
  *supervisorDelete() {
    yield this.apollo.mutate(
      {
        mutation: supervisorDeletePurchaseMutation,
        variables: {
          input: {
            purchaseId: this.purchase.id,
          },
        },
      },
      'supervisorDelete'
    );
  }

  @dropTask
  *checkMakeComplimentaryAllowed() {
    this.makeComplimentaryCheck = yield this.apollo.query(
      {
        query: checkMakeComplimentaryAllowedQuery,
        variables: {
          purchaseId: this.purchase.id,
        },
      },
      'checkMakeComplimentaryAllowed'
    );
    this.makeComplimentaryModalOpen = true;
  }

  @dropTask
  *makeComplimentaryPurchase() {
    yield this.apollo.mutate(
      {
        mutation: makeComplimentaryPurchaseMutation,
        variables: {
          input: {
            purchaseId: this.purchase.id,
            complimentarySaleType: this.complimentarySaleType,
          },
        },
      },
      'makeComplimentaryPurchase'
    );
  }

  @dropTask
  *saveWithNewDateTime(dateTime) {
    const result = yield this.apollo.mutate(
      {
        mutation: updatePurchaseMutation,
        variables: {
          purchaseId: this.purchase.id,
          input: {
            purchasedAtDateTime: dateTime,
          },
        },
      },
      'updatePurchase'
    );
    this.purchase = result.purchase;
  }
}
