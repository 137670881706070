import Service, { service } from '@ember/service';
import { addDays, todayInTimeZone } from 'my-phorest/utils/local-date-helpers';
import { queryManager } from 'ember-apollo-client';
import { dropTask, task, waitForProperty } from 'ember-concurrency';

import { RESCHEDULE_ACTIONS } from 'my-phorest/services/scheduling-provider';
import clientNextAppointmentQuery from 'my-phorest/gql/queries/client-next-appointment.graphql';

export default class PurchaseService extends Service {
  @service confirmDialog;
  @service intl;
  @service schedulingProvider;
  @service session;
  @service router;
  @service appointmentSlideOver;
  @queryManager apollo;

  appointmentsToRebook = [];

  createAndShowBasket(appointments, clientId) {
    const unpaidAppointments = appointments.filter(
      (appointment) => !appointment.isPaid
    );
    const unpaidRegularAppointmentIds = unpaidAppointments
      .filter(
        (appointment) =>
          !appointment.clientCourseItem &&
          !appointment.isPackageAppointment &&
          !appointment.isReward
      )
      .map((appointment) => appointment.id);

    const unpaidPackageAppointmentIds = unpaidAppointments
      .filter(
        (appointment) =>
          appointment.isPackageAppointment &&
          !appointment.isSpecialOfferAppointment
      )
      .map((appointment) => appointment.id);

    const unpaidSpecialOfferAppointmentIds = unpaidAppointments
      .filter((appointment) => appointment.isSpecialOfferAppointment)
      .map((appointment) => appointment.id);

    const unpaidCourseSessionIds = [];
    unpaidCourseSessionIds.push(
      ...unpaidAppointments
        .filter((appointment) => Boolean(appointment.clientCourseItem))
        .map((appointment) => appointment.id)
    );

    const rewards =
      unpaidAppointments.filter((a) => a.isReward).map((a) => a.id) || [];

    const queryParams = {
      appointmentIds: unpaidRegularAppointmentIds.length
        ? unpaidRegularAppointmentIds.join(',')
        : null,
      courseSessionIds: unpaidCourseSessionIds.length
        ? unpaidCourseSessionIds.join(',')
        : null,
      clientId,
      packageAppointmentIds: unpaidPackageAppointmentIds.length
        ? unpaidPackageAppointmentIds.join(',')
        : null,
      specialOfferAppointmentIds: unpaidSpecialOfferAppointmentIds.length
        ? unpaidSpecialOfferAppointmentIds.join(',')
        : null,

      redirectToPaymentMethods: true,
      appointmentRewardIds: rewards.length ? rewards.join(',') : null,
    };

    let date = appointments[0].date;
    if (date) {
      queryParams.date = date;
    }
    this.router.replaceWith('accounts.account.purchase.create-basket', {
      queryParams,
    });
  }

  @dropTask
  *shouldAskForNoShow(appointments) {
    let hasNoShows = this.#hasNoShows(appointments);
    if (this.#isOnlineBooking(appointments)) {
      let onlineDepositsEnabled =
        this.session.branch.payment.onlineDepositSettingsConfiguration
          ?.onlineBookingVariableDepositsEnabled;
      return yield hasNoShows && onlineDepositsEnabled;
    } else {
      let depositPaid = this.#anyDepositPaid(appointments);
      return yield hasNoShows && depositPaid;
    }
  }

  #hasNoShows(appointments) {
    return appointments.some((appointment) => appointment.isNoShow);
  }

  #anyDepositPaid(appointments) {
    return (
      Number(this.appointmentSlideOver.onlineDepositAmount(appointments)) > 0
    );
  }

  #isOnlineBooking(appointments = []) {
    return appointments.some((a) => a.flags.includes('BOOKED_ONLINE'));
  }

  @task
  *fetchClientNextAppointmentTask(clientId) {
    /* Please note:
       Next appointment means the closest in time from today (exclusive).
       It does NOT mean an appointment after the one that's involved in the payment process.
     */
    let today = todayInTimeZone(this.session.branchTimeZone);
    let tomorrow = addDays(today, 1);

    return yield this.apollo.query(
      {
        query: clientNextAppointmentQuery,
        variables: {
          clientId,
          startDate: tomorrow,
        },
      },
      'clientNextAppointment'
    );
  }

  @task
  *shouldShowRebookingPromptBeforePurchase(clientId) {
    if (this.session.business.showRebookingPromptBeforePurchase) {
      yield waitForProperty(this, 'warmNextAppointmentCache.isIdle');
      const nextAppointment =
        yield this.fetchClientNextAppointmentTask.perform(clientId);

      return !nextAppointment;
    }
    return false;
  }

  @task
  *shouldStartRebookingFlow(clientId) {
    let startRebookingFlow = false;
    const showRebookingPrompt =
      yield this.shouldShowRebookingPromptBeforePurchase.perform(clientId);

    if (showRebookingPrompt) {
      startRebookingFlow = yield this.confirmDialog.ask({
        title: this.intl.t('before-purchase-rebook-modal.title'),
        message: this.intl.t('before-purchase-rebook-modal.message'),
        continue: this.intl.t('global.yes'),
        cancel: this.intl.t('global.no'),
        leadingIcon: 'question-mark-circle',
      });
    }

    return startRebookingFlow;
  }

  @task
  *warmNextAppointmentCache(clientId) {
    if (clientId && this.session.business.showRebookingPromptBeforePurchase) {
      yield this.fetchClientNextAppointmentTask.perform(clientId);
    }
  }

  rebookAppointments(appointments) {
    let allowedActions = [RESCHEDULE_ACTIONS.COPY];

    this.schedulingProvider.startReschedulingBookings(
      appointments ?? this.appointmentsToRebook,
      { allowedActions, openAppointmentsSlideOverAfterCopy: false }
    );
  }

  rememberAppointmentsForRebooking(appointments) {
    this.appointmentsToRebook = appointments;
  }

  resetAppointmentsForRebooking() {
    this.appointmentsToRebook = [];
  }
}
